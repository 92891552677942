#Error404 .info-article {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

#Error404 .text-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

#Error404 h2 {
    padding:0px 20px;
    border-right: 1px solid lightgray;
}

#Error404 p {
    padding: 0px 20px;
}