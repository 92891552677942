/* Nav */
nav {
    padding:10px;
    text-align: center;
    background:rgb(15, 15, 15);
    box-shadow: 0px 2px 5px rgb(0, 0, 0);
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;

}

nav h1 {
    text-shadow: 3px 3px black;
}
    
nav a {
    display: inline-block;
    color:white;
    text-decoration: none;
    padding:5px;
    margin:5px 20px;
    vertical-align: middle;
    transition: all 0.3s ease;
    font-weight: bold;
}

nav button {
    border:0px;
    padding:5px;
    border-radius: 5px;
    background:rgb(200, 200, 200);
    font-weight: bold;
    cursor: pointer;
    width:140px;
    overflow: hidden;
}

nav button b {
    padding:7px;
    margin-left:10px;
    background: darkred;
    color:white;
    margin-right: -12px;
}

nav h1,
nav .nav-links {
    display: inline-block;
}

#connect-wallet {
    transition: all 0.3s ease;
    padding:8px;
    background:white;
    font-size:1rem;
    border:0px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
    margin: 5px 20px;
}

#connect-wallet:hover {
    background:rgb(216, 216, 216);
}

#connect-wallet.connected {
    background:rgb(168, 69, 69);
    color:white;
}

/* burger */
#burger {
    display: none;
    width:30px;
    z-index:99;
    position: fixed;
    left:20px;
    top:25px;
}

#burger span {
    width:20px;
    height:2px;
    background:white;
    display: block;
    margin:5px;
    transition:all 0.3s ease;
}

#burger span:first-child {
    transform-origin: center left;
    transform: rotate(0deg);
}

#burger.active span:first-child {
    transform: rotate(45deg);
}

#burger span:nth-child(2) {
    opacity: 1;
    transform: translateX(0);
}

#burger.active span:nth-child(2) {
    opacity: 0;
    transform: translateX(0px);
}

#burger span:nth-child(3) {
    transform-origin: center left;
    transform: rotate(0);
}

#burger.active span:nth-child(3) {
    transform: rotate(-45deg);
}

#burger-menu {
    display: none;
    position: fixed;
    justify-content: center;
    align-items: center;
    background:rgb(15, 15, 15);
    height:100vh;
    margin:0px;
    top:0;
    left:0;
    z-index:9;
    transform: translateX(-100%);
    transition:all 0.3s ease;
}

#burger-menu.active {
    transform: translateX(0px);
}

#burger-menu .links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    text-transform: uppercase;

}

#burger-menu a {
    display: block;
    font-size: 2rem;
    padding: 12px;
    width: 100%;
    margin: 10px;
    color:white;
    text-decoration: none;
    padding:5px;
    margin:5px 20px;
    text-align: center;
    transition: all 0.3s ease;
}

#burger-menu button {
    display: block;
    font-size: 2rem;
    padding:10px;
    margin: 10px auto;
    border:0px;
    border-radius: 10px;
    background:rgb(200, 200, 200);
    width:100%;
    max-width:300px;
    overflow: hidden;
}

#burger-menu button b {
    padding:18px;
    margin-left:10px;
    background: darkred;
    color:white;
    margin-right: -18px;
    font-size: 1.5rem;
}

@media screen and (max-width: 820px) {
    nav .nav-links {
        display: none;
    }
    #burger {
        display: inline-block;
    }
    #burger-menu {
        display: flex;
    }
}

@media screen and (max-width: 330px) {
    #burger-menu a {
        font-size: 1rem;
    }
    #burger-menu button {
        font-size: 1rem;
        max-width:180px;
    }
    #burger-menu button b {
        font-size: 1rem;
    }

}

/* banners */

#banners {
    display: block;
    position: fixed;
    bottom:20px;
    left:20px;
    z-index: 3;
}

#walletStatus {
    display: flex;
    justify-content: center;
    align-items: center;
    padding:5px;
    border-radius: 5px;
    background:rgb(30, 30, 30);
    font-weight: bold;
    color:rgb(180, 180, 180);
    box-shadow: 0px 2px 2px rgb(0, 0, 0);
}

#statusIndicator {
    display: block;
    width:10px;
    height:10px;
    min-width: 10px;
    background:black;
    margin:5px;
    border-radius: 10px;
}

#statusIndicator.connected {
    background:rgb(0, 255, 0);
}

#statusIndicator.disconnected {
    background:red;
}

#WEB3_CONNECT_MODAL_ID .web3modal-modal-lightbox{
    z-index:999;
}

#gas {
    margin-bottom:5px;
    border-radius: 5px;
    background:rgb(30, 30, 30);
    font-weight: bold;
    color:rgb(180, 180, 180);
    box-shadow: 0px 2px 2px rgb(0, 0, 0);
    cursor: pointer;
    width:60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#gas span {
    display: block;
    margin:5px;
}

#gas svg {
    margin:5px;
    width:12px;
}