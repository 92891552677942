.policy-article {
    max-width:800px;
    margin:0px auto;
    padding: 20px;
    padding-top:100px;
}

.policy-article span {
    display: block;
    margin-bottom: 30px;
}

.policy-article h3 {
    margin-bottom:5px;
}

.policy-article .text-container p {
    margin-bottom: 5px;
}


.policy-article a {
    text-decoration: underline;
    font-weight: bold;
    color:white;
}

.policy-article .text-container {
    margin-bottom: 40px;
}

.policy-article .block {
    display: block;
    margin-bottom:5px;
}

.policy-article table {
    border-collapse: collapse;
    border:5px solid rgb(139, 139, 139);
    width:100%;
    text-align: left;
    margin-bottom:20px;
    box-shadow: 0px 5px 5px rgb(27, 27, 27);
}

.policy-article table th {
    width:80px;
    border:1px solid rgb(80, 80, 80);
    padding:5px;
}

.policy-article table td {
    border: 1px solid rgb(80, 80, 80);
    padding:5px;
}