#main-content {
    visibility: hidden;
    display:none;
}

section {
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

h2 {
    margin: 20px;
    font-size: 4rem;
    color: rgb(255, 255, 255);
    text-shadow: 0px 5px 5px black;
}

h2.glitch::after,
h2.glitch::before {
    color:rgb(255, 255, 255);
}

/* Bots */
#Bots {
    padding: 0px;
    background: rgb(21, 21, 21);
}

#Bots h2 {
    font-family: 'Caveat', cursive;
}

#Bots h2.glitch::after,
#Bots h2.glitch::before {
    background:rgb(21, 21, 21);
}

#Bots .dev-container,
#Mograph .mograph-container {
    padding:20px;
    margin: 0 auto;
    width:100%;
    text-align: center;
}

#Bots .projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#Bots .card {
    min-width: 300px;
    max-width: 500px;
    flex:1;
    background:rgb(15, 15, 15);
    margin:10px;
    border-radius:5px;
    padding:20px;
    text-align: left;
    box-shadow:0px 0px 5px rgb(0, 0, 0);
    border:1px solid rgb(42, 42, 42);
}

#Bots .card:hover {
    background:rgb(0, 0, 0);
    box-shadow:0px 0px 5px rgb(14, 14, 14);
}

#Bots a {
    color:white;
    text-decoration: none;
}

#Bots .card span {
    display: block;
    text-decoration: underline;
    margin-bottom:10px;
    color:rgb(161, 161, 161);
}

#Bots .card h3 {
    font-size: 1.5rem;
    margin-right: 10px;;
}

#Bots .card .card-header {
    display: flex;
    margin-bottom:10px;
}

#Bots .card svg {
    width:30px;
    height:30px;
}

/* Mograph */
#Mograph {
    background: #101010;
}

#Mograph h2 {
    font-family: 'Qwitcher Grypen', cursive;
    font-size: 5rem;
}

#Mograph h2.glitch::after,
#Mograph h2.glitch::before {
    background:#101010;
}

#Mograph .mograph-container {
    padding:50px 0px;
    position: relative;
    overflow:hidden;
}

#Mograph .mograph {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#Mograph .mograph img {
    max-width: 300px;
    margin:10px;
    border-radius: 10px;
    box-shadow:0px 0px 5px rgb(0, 0, 0);
}

#Mograph .infinite-scroll .fade-away {
    background: linear-gradient(90deg,#101010 15%,rgba(16, 16, 16,0));
    left:-1;
    position: absolute;
    height:100%;
    width:15%;
    z-index: 1;
}
#Mograph .infinite-scroll  .fade-in {
    background: linear-gradient(-90deg,#101010 15%,rgba(16, 16, 16,0));
    right:-1px;
    position: absolute;
    height:100%;
    width:15%;
    z-index: 1;
}
@keyframes move-left {
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
}
#Mograph .mograph {
    animation: move-left 20s linear infinite;
    display: flex;
    margin:0px auto;
    height:max-content;
    width: max-content;
    position: relative;
    overflow:hidden;
}

/* Blockchain */
#Blockchain {
    padding:50px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#Blockchain h2 {
    font-size: 4rem;
    font-family: 'Silkscreen', cursive;
}

#Blockchain h2.glitch::after,
#Blockchain h2.glitch::before {
    background:#181818;
}

#Blockchain article {
    padding:20px;
}

#Blockchain .lair-container {
    position: relative;
    overflow:hidden;
    text-align: center;
}

#Blockchain .flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

#Blockchain .text-container {
    text-align: left;
    max-width: 400px;
    padding: 20px;
}

#Blockchain .text-container h3 {
    font-size: 2.5rem;
}

#Blockchain .text-container p {
    font-size: 1.8rem;
}

#Blockchain .text-container p a {
    font-weight: bold;
    color:#5C23D1;
    text-decoration: none;
}

#Blockchain .text-container p a:last-of-type {
    font-weight: bold;
    color:#234ed1;
    text-decoration: none;
}

#Blockchain .text-container b {
    font-weight: bold;
    color:rgb(185, 21, 21);
    text-decoration: none;
}

#Blockchain .content-container {
    width: 500px;
    height:400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#Blockchain .content-container .imgs {
    position: relative;
    max-width: 400px;
}

#Blockchain .content-container img {
    width:300px;
    filter: drop-shadow(0px 5px 5px #000000);
    border-radius: 5px;
}

#Blockchain .content-container img:first {
    z-index: 1;
}

#Blockchain .content-container .background {
    filter: brightness(50%);
}

#Blockchain .content-container .background:first-of-type {
    transform: rotate(30deg) translateX(20px) scale(0.7);
    z-index: 1;
    position: absolute;
    top:-10px;
    left:100px;
}

#Blockchain .content-container .background {
    transform: rotate(-30deg) translateX(-20px) scale(0.7);
    z-index: 1;
    position: absolute;
    top:-10px;
    right:100px;
}

#Blockchain .content-container img.foreground {
    z-index: 2;
    position: relative;
}

@media screen and (max-width: 600px) {
    #Blockchain h2 {
        font-size: 2.5rem;
    }
}