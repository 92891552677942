@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@500&family=Jacques+Francois+Shadow&family=Qwitcher+Grypen:wght@700&family=Silkscreen&display=swap');

#Home {
    background: rgb(14, 14, 14);
    margin: 0px;
    overflow: hidden;
    z-index: 5;
    position: relative;
}

#Home form {
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#Home button {
    font-size: 5rem;
    font-weight: bold;
    border:0px;
    color:#3d3d3d;
    text-shadow: 5px 5px black;
    background: rgb(14, 14, 14);
    transition: all 0.3s ease;
    height:100vh;
    width:100%;
}

@media screen and (max-width:800px) {
    #Home button {
        font-size:3rem;
    }
}

#Home button:active {
    color:#737373;
    text-decoration: underline;
}

#Home span {
    display: block;
    position:fixed;
    top:0px;
    width:100%;
    padding:5px;
    font-size:1rem;
    font-weight: bold;
    color:white;
    text-align: center;
    text-transform: uppercase;
    font-family:inherit;
    color:#3d3d3d;
}

.glitch {
    color: gray;
    position:relative;
}

@keyframes noise-anim {
    0% { clip-path: inset(75% 0 14% 0); }
    5% { clip-path: inset(81% 0 8% 0); }
    10% { clip-path: inset(8% 0 14% 0); }
    15% { clip-path: inset(14% 0 70% 0); }
    20% { clip-path: inset(23% 0 33% 0); }
    25% { clip-path: inset(95% 0 1% 0); }
    30% { clip-path: inset(45% 0 18% 0); }
    35% { clip-path: inset(19% 0 42% 0); }
    40% { clip-path: inset(62% 0 25% 0); }
    45% { clip-path: inset(36% 0 25% 0); }
    50% { clip-path: inset(67% 0 6% 0); }
    55% { clip-path: inset(84% 0 16% 0); }
    60% { clip-path: inset(30% 0 30% 0); }
    65% { clip-path: inset(16% 0 39% 0); }
    70% { clip-path: inset(13% 0 17% 0); }
    75% { clip-path: inset(77% 0 14% 0); }
    80% { clip-path: inset(66% 0 19% 0); }
    85% { clip-path: inset(12% 0 4% 0); }
    90% { clip-path: inset(99% 0 1% 0); }
    95% { clip-path: inset(36% 0 63% 0); }
    100% { clip-path: inset(22% 0 41% 0); }
}

.glitch::after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 darkred;
    top: 0;
    color: gray;
    background: rgb(14, 14, 14);
    overflow: hidden;
    animation: noise-anim 2s infinite linear alternate-reverse;
    width:100%;
}

@keyframes noise-anim-2 {
    0% { clip-path: inset(46% 0 28% 0); }
    5% { clip-path: inset(30% 0 6% 0); }
    10% { clip-path: inset(59% 0 19% 0); }
    15% { clip-path: inset(11% 0 7% 0); }
    20% { clip-path: inset(41% 0 32% 0); }
    25% { clip-path: inset(52% 0 21% 0); }
    30% { clip-path: inset(18% 0 23% 0); }
    35% { clip-path: inset(56% 0 44% 0); }
    40% { clip-path: inset(71% 0 1% 0); }
    45% { clip-path: inset(83% 0 2% 0); }
    50% { clip-path: inset(99% 0 1% 0); }
    55% { clip-path: inset(39% 0 10% 0); }
    60% { clip-path: inset(56% 0 37% 0); }
    65% { clip-path: inset(1% 0 50% 0); }
    70% { clip-path: inset(86% 0 10% 0); }
    75% { clip-path: inset(82% 0 8% 0); }
    80% { clip-path: inset(60% 0 37% 0); }
    85% { clip-path: inset(19% 0 35% 0); }
    90% { clip-path: inset(81% 0 2% 0); }
    95% { clip-path: inset(32% 0 44% 0); }
    100% { clip-path: inset(16% 0 9% 0); }
}

.glitch::before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 darkblue;
    top: 0;
    color: gray;
    background: rgb(14, 14, 14);
    overflow: hidden;
    animation: noise-anim-2 15s infinite linear alternate-reverse;
    width:100%;
}