* {
  margin:0px;
  padding:0px;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#181818;
  color:rgb(249, 249, 249);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 2rem;
  margin:0px;
}

h2 {
  font-size: 2rem;
  margin:0px;
}

header {
  max-width:600px;
  margin:0px auto;
  padding:20px;
  text-align: center;
  border:5px solid rgb(78, 78, 78);
  margin-bottom:20px;
  box-shadow: 0px 5px 5px rgb(26, 26, 26);
  background:rgb(20, 20, 20);
  color:rgb(180, 180, 180);
}

header h3 {
  font-size: 2rem;
  margin-bottom: 10px;
}

header p {
  font-size: 1.2rem;
}

header img {
  width: 100%;
  text-shadow: 0px 0px 5px black;
}

@media screen and (max-width: 400px) {
  h1 {
    font-size: 2rem;
  }
}